<template>
  <div class="flex flex-col gap-4">
    <h2 class="font-medium">Add Indicator</h2>
    <div
      v-for="(ind, idx) in indicators.indicators"
      :key="idx"
      class="bg-white rounded-md h-auto shadow-sm p-8 gap-8 flex flex-col"
    >
      <div>
        <span class="text-sm text-gray-500 font-medium">Indicator Name</span>
        <small
          v-if="ind.name.length === 100 || ind.name.length < 3"
          class="text-gray-500"
        >
          (Min: 3 Characters Max: 100 Characters)</small
        >
        <input
          type="text"
          v-model="ind.name"
          placeholder="Enter Indicator Name"
          class="appearance-none block w-full h-10 text-gray-700 border rounded-lg py-3 px-4 mb-3 mt-1 leading-tight focus:outline-none focus:bg-white"
          @change="hasError(idx)"
          maxlength="100"
          minlength="3"
          :class="
            isEmpty[idx]?.indicatorError
              ? 'border-red'
              : 'border-gray-300 focus:border-gray-400'
          "
        />
      </div>
      <div>
        <span class="text-sm text-gray-500 font-medium">Sub Indicators</span>
        <small
          v-if="sub_indicator.length === 100 || sub_indicator.length < 3"
          class="text-gray-500"
        >
          (Min: 3 Characters Max: 100 Characters)</small
        >

        <div
          class="border border-gray-100 rounded-md mt-1 mb-1"
          :class="
            isEmpty[idx]?.subIndicatorError
              ? 'border-red'
              : 'border-gray-300 focus:border-gray-400'
          "
        >
          <div class="flex flex-wrap">
            <div
              v-for="(subIndicator, index) in ind.sub_indicators"
              :key="index"
              class="bg-gray-100 justify-center items-center rounded-md m-2 px-3 py-2 text-xs"
            >
              <div class="flex justify-between items-center">
                <div class="text-gray-500 font-normal">
                  {{ subIndicator.name }}
                </div>
                <div
                  class="w-4 ml-3 cursor-pointer"
                  @click="removeChip(subIndicator, ind)"
                >
                  <img src="../../../../assets/images/cancel.png" />
                </div>
              </div>
            </div>
            <input
              type="text"
              placeholder="Press Enter to add Sub-Indicators"
              class="appearance-none block w-full h-5 text-gray-700 py-3 px-4 my-3 leading-tight focus:outline-none focus:bg-white"
              v-model="sub_indicator"
              @change="hasError(idx)"
              maxlength="100"
              minlength="3"
              @keyup.enter="addSubIndicator(ind, idx)"
            />
          </div>
        </div>

        <small class="text-gray-500" v-if="sub_indicator.length > 0"
          >Press Enter to add Sub-Indicators
        </small>
      </div>
    </div>
    <button
      class="bg-white border-gray-300 border w-full h-12 font-light text-grey rounded-lg"
      :onClick="addIndicator"
    >
      + Add New Indicator
    </button>
    <div class="flex gap-16 justify-between">
      <button
        class="bg-transparent border border-teal text-teal h-12 w-34 font-light rounded-md"
        @click="
          router.push({ name: 'hq-monitoringOfPracticeFunctionsQofDashboard' })
        "
        :class="isCancelDisabled ? 'cursor-not-allowed opacity-50' : ''"
        :disabled="isCancelDisabled"
      >
        Cancel
      </button>
      <button
        class="bg-teal h-12 w-34 font-light text-white rounded-md"
        :class="isSaveDisabled ? 'cursor-not-allowed opacity-50' : ''"
        :onClick="createIndicator"
        :disabled="isSaveDisabled"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const router = useRouter();
const store = useStore();
const indicator = ref("");
const sub_indicator = ref([]);
const isEmpty = ref([{ indicatorError: false, subIndicatorError: false }]);

const indicators = ref({
  indicators: [
    {
      name: indicator.value,
      sub_indicators: [],
    },
  ],
});

const rules = {
  indicators: {
    indicators: {
      $each: helpers.forEach({
        name: { required },
        sub_indicators: { required },
      }),
    },
  },
};

const v$ = useVuelidate(rules, { indicators });

const addIndicator = async () => {
  await v$.value.$validate();
  if (v$.value.$error) {
    isEmpty.value = indicators.value.indicators.map((indicator) => {
      let isEmptyIndicator = false;
      let isEmptySubIndicator = false;
      if (indicator.name === "") {
        isEmptyIndicator = true;
      }
      if (indicator.sub_indicators.length === 0) {
        isEmptySubIndicator = true;
      }
      return {
        indicatorError: isEmptyIndicator,
        subIndicatorError: isEmptySubIndicator,
      };
    });
    return;
  }
  isEmpty.value.forEach((error) => {
    error.indicatorError = false;
    error.subIndicatorError = false;
  });
  indicators.value.indicators.push({
    name: indicator.value,
    sub_indicators: [],
  });
  isEmpty.value.push({ indicatorError: false, subIndicatorError: false });
};

const addSubIndicator = (indicator, index) => {
  if (sub_indicator.value.length >= 3) {
    hasError(index);
    indicators.value.indicators[index].sub_indicators.push({
      name: sub_indicator.value,
    });
    sub_indicator.value = "";
  }
};

const removeChip = (subIndicator, indicator) => {
  const idx = indicator.sub_indicators.indexOf(subIndicator);
  indicator.sub_indicators.splice(idx, 1);
};

const createIndicator = async () => {
  await v$.value.$validate();
  if (v$.value.$error) {
    isEmpty.value = indicators.value.indicators.map((indicator) => {
      let isEmptyIndicator = false;
      let isEmptySubIndicator = false;
      if (indicator.name === "") {
        isEmptyIndicator = true;
      }
      if (indicator.sub_indicators.length === 0) {
        isEmptySubIndicator = true;
      }
      return {
        indicatorError: isEmptyIndicator,
        subIndicatorError: isEmptySubIndicator,
      };
    });
    return;
  }
  isEmpty.value.forEach((error) => {
    error.indicatorError = false;
    error.subIndicatorError = false;
  });
  store.dispatch("hqQof/createIndicator", indicators.value);
  router.push({ name: "hq-monitoringOfPracticeFunctionsQofDashboard" });
};

const hasError = (id) => {
  if (
    isEmpty.value[id].indicatorError &&
    indicators.value.indicators[id].name.length > 0
  ) {
    isEmpty.value[id].indicatorError = false;
  }

  if (isEmpty.value[id].subIndicatorError && sub_indicator.value.length > 0) {
    isEmpty.value[id].subIndicatorError = false;
  }
};

const isSaveDisabled = computed(() => {
  return (
    indicators.value.indicators.some(
      (indicator) => !indicator.name || indicator.sub_indicators.length === 0
    ) || sub_indicator.value.length !== 0
  );
});

const isCancelDisabled = computed(() => {
  return indicators.value.indicators[0].name.length < 1;
});
</script>
